.wrap-element-relative {
  height: 100vh;
  width: 100%;
  position: relative; }

.wrap-element-relative .amenagement_img {
  position: absolute;
  left: 40px;
  bottom: 60px;
  max-width: 270px;
  -webkit-animation: none;
  animation: none;
  transition: none;
  animation-delay: 2s;
  padding: 1em;
  background-color: rgba(0,0,0,0.6);  }


.main_header {
  text-align: center;
  background-position: center bottom;
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
.main_header .logo_col {
  animation-delay: 0.8s;
  z-index: 9; }
.main_header .logo_col hr {
  margin: 5px auto; }
.main_header .logo_col h3 {
  color: white;
  font-size: 4em;
  font-family: "Tekton W01 Regular";
  text-transform: uppercase; }
.main_header .logo_col h3.paysagiste {
  font-family: "Speedball W00 Regular";
  font-size: 4em;
  letter-spacing: 0.4245em;
  padding-left: 0.5em; }
.main_header ul {
  margin-bottom: 60px;
  padding-top: 30px; }
.main_header ul li {
  display: inline-block;
  padding: 0 30px;
  position: relative; }
.main_header ul li a {
  color: #ffffff;
  font-size: 1.5em;
  font-family: "Helvetica-bold"; }
.main_header ul li:after {
  content: "";
  height: 20px;
  position: absolute;
  background-color: #fff;
  width: 1px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0; }
.main_header ul li:nth-child(3):after {
  display: none; }
.main_header ul li.visible_tab {
  display: none; }

@media(max-width: 1799px) {
  .banner_band {

    background-image: url('../img/amenagement-paysager-laurentides-xl.jpg');


}
}

@media(max-width: 1499px) {
  .banner_band {
    background-image: url('../img/amenagement-paysager-laurentides-l.jpg');
}
}

@media(max-width: 1049px) {
  .banner_band {
    background-image: url( '../img/amenagement-paysager-laurentides-m.jpg');

}
}

.main_page {
  overflow: hidden; }

.wrap-element {
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 99999; }

.wrap-element-relative {
  height: 100vh;
  width: 100%;
  position: relative; }
.wrap-element-relative .amenagement_img {
  position: absolute;
  left: 40px;
  bottom: 60px;
  max-width: 270px;
  -webkit-animation: none;
  animation: none;
  transition: none;
  animation-delay: 2s;
  padding: 1em;
  background-color: rgba(0,0,0,0.6);  }


.wrap-element-relative .appq_logo {
  position: absolute;
  bottom: 80px;
  right: -65px;
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  width: 300px; }

.wrap-element-relative .banner_bottom li.number_banner a {
  font-size: 2.5rem;
  margin-right: 20px;
  color: #fff; }
.wrap-element-relative .banner_bottom {
  position: absolute;
  right: 40px;
  bottom: 30px; }
.wrap-element-relative .banner_bottom li {
  display: inline-block;
  vertical-align: middle; }

.banner_band {
  height: 100vh;
  background-image: url( '../img/amenagement-paysager-laurentides.jpg');

background-color: #000;
min-height: 750px;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-animation: zoomvin 10s ease-in infinite;
animation: zoomvin 10s ease-in infinite;
transition: all .5s ease-in-out; }
.banner_band .container {
  height: 100%;
  position: relative; }
.banner_band picture {
  width: 100%;
  height: 100%;
  position: absolute; }
.banner_band picture img {
  max-width: inherit;
  width: 100%; }

.wrap-element-relative .appq_logo {
  position: absolute;
  bottom: 80px;
  right: -65px;
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  width: 300px; }

.wrap-element-relative .banner_bottom li.number_banner a {
  font-size: 2.5rem;
  margin-right: 20px;
  color: #fff; }
.wrap-element-relative .banner_bottom {
  position: absolute;
  right: 40px;
  bottom: 30px; }
.wrap-element-relative .banner_bottom li {
  display: inline-block;
  vertical-align: middle; }



@media(max-width: 990px) {
  .banner_band {
    background-image: url( '../img/amenagement-paysager-laurentides-s.jpg');

}
}
@media(max-width: 767px) {
  .banner_band {

    background-image: url( '../img/amenagement-paysager-laurentides-xs.jpg' );

}
}
