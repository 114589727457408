@media all and (max-width: 767px) {
  html * {max-height: 1000000px;}

  .visible_tab {display: block !important;}
  .main_header ul li:after , .main_header ul , .banner_band .banner_bottom{display: none}
  .container{padding-left: 15px; padding-right: 15px;}
  p {font-size: 18px; line-height: 24px;}
  .main_header {
    background-color:#555555;
    background-image: none;
    padding: 15px 20px;
    position: relative;
    display: flex;
    #toggle{display: block; position: absolute; right: 20px; top: 20px;}

    .nav_main{order: 2}

    .logo_main{max-width: 140px;}
    ul{
      position: absolute; top: 100%; left: 0; padding:10px 0; margin-bottom: 0;background-color:#4a4a4a;
      li {
        padding: 0;
        a{font-weight: 400; padding:10px 20px; display: inline-block; font-family: "Helvetica-regular"}
      }
    }

  }
  .banner_band{
    min-height: auto;
    .container{
      height: calc(100% - 60px);
      .amenagement_img{left:0; right:0; margin: auto; bottom: 90px;}
    }

  }

  .grid_band {
    .col-md-12{
      margin-bottom: 110px !important;
      padding: 0 25px;
      .head_cover{
        padding: 20px !important;
        h1{
          font-size: 2.6rem !important;
        }
        &:before{height: 71px !important; width: 58px !important; background-size: 100% auto !important}
      }
    }
    [class^="col-md"]{margin-bottom: 20px}
    h2{
      font-size: 1.5rem;
      b{
        font-size: 4rem;
        em{font-size: 2rem !important}
      }
    }
    .img_cover{
      padding: 20px; min-height: 320px;
      &:before{margin-right: -0.35em}
    }
    .architecture{
      .img_cover{background-size: 250px auto; background-position: top right}
    }
    .side_img{
      h2{right: 20px; top:20px;}
    }
  }
  .grid_band .col-md-12 .head_cover .border_spec::before, .grid_band .col-md-12 .head_cover .border_spec::after, .grid_band .col-md-12 .head_cover .border_spec_righ::before, .grid_band .col-md-12 .head_cover .border_spec_righ::after{width:28.5%;}
  .grid_band .col-md-12 .head_cover .border_spec:after{left: -4.5%; width:33% !important}
  .grid_band .col-md-12 .head_cover .border_spec_righ:after {left: auto; right: -4.5%; width: 33% !important;}
  .small_container{padding: 0 20px;}
  .entreprise {
    padding: 40px 0 50px;
    .col_cover {
      h3{font-size:  2.8rem}
      p{font-size:16px;}
      &:after{display: none}
    }
    .loglo_content{
      padding-left: 15px; padding-right: 15px;
      img{display: block; margin: 0 auto}
    }
    .col-md-12{
      margin-top: 30px;
    }
  }
  .call_to_action {
    .call_content{padding:30px 20px;
      h2{font-size:1.5rem !important;}
      p{margin-bottom: 1rem;}
    }

  }
  .footer_main {
    padding-top: 120px;
    .footer_bottom {
      h3{font-size:1.6rem; margin-bottom: 20px; padding: 0 15px;}
      form{width:100% !important; padding-left: 15px;
        &:before{display: none !important}
      }
      label{display: block; font-size: 1.2rem !important}
      .container{padding-left: 15px; padding-right: 15px}
    }
    input[type="email"]{text-align: left !important; width:50% !important; margin-right: 10px !important}
    input[type=submit]{text-align: left !important; padding: 10px;}
    .facebook_footer{padding-top: 20px !important; padding-left: 15px !important;}
  }

  .main_header .logo_col h3 , .main_header .logo_col h3.paysagiste{font-size:1em}
  .wrap-element-relative .banner_bottom{bottom:10px;}
  .wrap-element-relative .banner_bottom li.number_banner a{font-size:2em}
  .wrap-element-relative .amenagement_img{bottom:240px;}
  .wrap-element{margin-top:80px;}
  .banner_band , .wrap-element{min-height:600px;}
  .wrap-element-relative .appq_logo{bottom:60px}
  .parallax_band h2{font-size:50px;}
  .parallax_band p{font-size:16px;}

  .grid-item, .grid-sizer{width:100%; margin-bottom:20px !important; padding:0 20px;}

  .filter_band .button{max-width:80px;}
  .filter_band .button > img{max-width:100%;}

  .parallax_band .owl-prev{margin-left:-20px;}
  .parallax_band .owl-next{margin-right:-20px;}
  .parallax_band .triangle_par{height:80px !important;}
  .parallax_band .item{padding:0 15px;}
  .parallax_band{padding-bottom:100px;}

}
/* iPad landscape ┬╖ width: 1024px */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {float: left;}
  .col-md-12 {width: 100%;}
  .col-md-11 {width: 91.66666667%;}
  .col-md-10 {width: 83.33333333%;}
  .col-md-9 {width: 75%;}
  .col-md-8 {width: 66.66666667%;}
  .col-md-7 {width: 58.33333333%;}
  .col-md-6 {width: 50%;}
  .col-md-5 {width: 41.66666667%;}
  .col-md-4 {width: 33.33333333%;}
  .col-md-3 {width: 25%;}
  .col-md-2 {width: 16.66666667%;}
  .col-md-1 {width: 8.33333333%;}

  p{font-size: 18px; line-height: 22px;}
  .hidden-tab {display: none;}


  .container{padding-left: 30px; padding-right: 30px;}

  .main_header{
    ul {
      margin-bottom: 40px;
      li{padding:0 20px;
        a{font-size: 1.2rem !important;}
      }
    }
  }

  .banner_band{
    .appq_logo{font-size: 1.2rem;}
    .amenagement_img{max-width:200px;}
    .logo_col{
      a{max-width:350px; font-size:1em}
    }
    .banner_bottom {
      li.number_banner {
        a{font-size: 1.5rem}
      }
    }
  }

  .grid_band{
    .col-md-12{

      .head_cover{

        h1{
          font-size:4rem; text-align:center; word-spacing:0.2em; display:inline-block
        }
        &:before{width:98px; height: 111px;}
      }
    }
    .img_cover{padding: 15px;}
  }
  h2{line-height: 24px !important; font-size: 1.5rem !important; margin-bottom: 5px !important;
    b{font-size:2.5rem !important}
  }
  .architecture {
    .img_cover{background-size: 150px;}

  }
  .side_img {
    h2{right: 40px !important; top: 40px !important;}
  }

  .grid_band .col-md-12 .head_cover .border_spec::before, .grid_band .col-md-12 .head_cover .border_spec::after, .grid_band .col-md-12 .head_cover .border_spec_righ::before, .grid_band .col-md-12 .head_cover .border_spec_righ::after{width:28.5%;}
  .grid_band .col-md-12 .head_cover .border_spec:after{left: -2.1%; width: 30.6% !important;}
  .grid_band .col-md-12 .head_cover .border_spec_righ:after {left: auto; right: -2.1%; width: 30.6% !important;}

  .entreprise {
    .col_cover{
      h3 {  font-size: 4.8rem;	}
      p{font-size: 22px;}
    }
    .loglo_content{
      img{max-width: 58px;}
    }
  }
  .call_to_action {
    .call_content{padding: 40px;}
  }
  .footer_main {
    .footer_bottom {
      h3{font-size: 2.3rem}

      form{width:50%;
        &:before{display: none}
      }
      label{font-size:1.2rem; display: block}
      .facebook_footer{padding-top: 0;}
      input{width:46.4% !important; margin-right: 8px !important;}
    }



  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .container{padding-left: 50px; padding-right: 50px;}
  .entreprise {
    .col_cover{
      p{font-size: 26px; margin-top: 10px;}
    }
  }
  p {font-size: 1.3rem; line-height: 26px;}
  .call_to_action
  {
    .call_content {
      h2{font-size: 2.5rem}
    }
  }
  .grid_band {
    .col-md-12{
      .head_cover{
        &:before{width: 118px; height: 131px; background-size:100% auto}
        h1{font-size:5rem !important;}
      }
    }
    .img_cover{padding: 20px;}
    .architecture .img_cover{background-size: 180px}
    h2{font-size: 2rem}
    .side_img{
      h2 {
        position: absolute;
        right: 60px;
        top: 50px;}
    }
  }
  .footer_main
  {
    .footer_bottom
    {
      h3{font-size:3.4rem}
      input{width:31%;}
    }
    .facebook_footer{padding-top: 40px;}
    form {width: 46% !important; padding-left: 0;}
  }

}
@media only screen and (min-width: 1300px) and (max-width: 1620px) {
  .footer_main
  {
    .footer_bottom
    {
      h3{font-size:3.4rem}
      input{width:34%;}
    }
    .facebook_footer{padding-top: 40px;}
    form {width: 42%; padding-left: 0;}
  }
  .call_to_action
  {
    .call_content {
      h2{font-size: 3.5rem}
    }
  }

}
/* ================================= Small Computer #Sc ================================= */
@media only screen and (min-width: 1300px) {

}
