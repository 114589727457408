#section2, #section3, #section4{
  .wrapNav{

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5em;
    background-color: rgba(0,0,0,0.8);
    z-index: 9;
  }

  h1{

    color:white;
  }

}


#section2{





  h3{
    color:white;
    position: absolute;
    right: 120px;
    top: 80px;
    text-align: center;
    letter-spacing: 0.15em;
    font-family: "Helvetica LT W01 Roman";
    font-size:2rem;


    .Stitre{


    }

    .SStitre{

      font-size: 5rem;
      font-family: "Born Ready W00 Regular";
      display: block;
      font-weight: 400;

    }

  }





}



#navAccHaut{

  a{

    .fab{

      color:#5096b0;
      margin:0 1em;
    }
  }

}




#navAccBas{

    margin-bottom:2em;

      a {

        font-size: 1.8em;

        span {

          color: white;
          font-family: "Born Ready W00 Regular";
        }

        color: #5096a0;
      }
}



#slide1-2 .fp-bg{
  background-color: #4a4949;

}



#section1{

  background-color: #4e4c4b;

  .arbre{

    img{

      position: absolute;
      bottom: 0;
      right: 0;
      height: auto;
      width: 76%;

    }

  }

  h1{

    position: absolute;
    left: 0;
    bottom: 36%;
    font-size: 3rem;
    background-color: #000;
    padding: 0.5em;
    padding-left: 0.5em;
    padding-left: 1em;
    padding-left: 3em;

    .logo-dominique{



      color: white;

      font-family: "Tekton W01 Regular";
      text-transform: uppercase;
      border-bottom:solid 2px #e92226;
      display: inherit;
      font-size:1.1em;


    }
    .logo-paysagiste{

      font-family: "Speedball W00 Regular";
      text-transform:uppercase;
      letter-spacing: 0.4245em;
      padding-left: 0.5em;
      display: inherit;

    }


  }


  nav{

    position:absolute;
    bottom:0;
    left: 0;
    width: 100%;

      ul{
        float:left;
        width:100%;
          li{

            width:30%;
            float: left;
          }
      }
  }


}



#slide1-1 .fp-bg{
  background-color: #4a4949;
  background-position: center 80%;
}




h1{
  font-weight: 900;
  font-size: 8em;
  -webkit-text-shadow: 0 .3rem .9rem rgba(0,0,0,.4);
  text-shadow: 0 0.3rem 0.9rem rgba(0,0,0,.4);
  letter-spacing: -3px;
}
h1,
.intro p{
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
}


.intro p{
  text-shadow: -1px 1px 8px rgba(0,0,0,.8);
}


#section1 h1{
  -webkit-text-shadow: none;
  text-shadow: none;
}


#slide1-1 h1{
  color: #444;
}
/*
* Setting the backgrounds for each section / slide
*/
#section4 .fp-bg:before,
#section2 .fp-bg:before{
  content: '';
  position: absolute;
  display: block;
  top:0;
  bottom:0;
  height: 100%;
  width: 100%;
}


.fp-bg{
  background-size: cover;
  transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}







#slide2-1 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
  background-position: center 60%;
}

#slide2-2 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager-cuisine.jpg');
  background-position: center 60%;
}

#slide2-3 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
  background-position: center 60%;
}

#slide2-4 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
  background-position: center 60%;
}

#slide2-5 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
  background-position: center 60%;
}



#section2 h1{
  color: #fff;
}
#slide3-1 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
  background-position: 50% 65%;
}
#slide3-2 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
}
#slide3-3 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
}
#slide3-4 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
}
#section4 .fp-bg{
  background-image: url('../img/accueil/amenagement-paysager.jpg');
}
.button a.button-purchase:hover {
  background-color: #3cc63c !important;
  color: #fff !important;
}
.documentation a{
  font-size: 13px;
  margin: 20px 0;
  padding: 0;
  color: #222;
}

/*
* Configurations
*/
#actions{
  position:fixed;
  top: 73px;
  left: 30px;
  z-index: 99999;
}
#actions li{
  background: rgba(0,0,0,0.8);
  padding: 7px;
  margin: 2px;
  color: #ccc;
  display: inline-block;
  cursor: pointer;
}
#actions li:hover{
  color: #fff;
  background: #81A140;
}
#actions .active{
  color: #fff;
  background: #81A140;
}
